import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icons';
import { init as emailjsInit  } from '@emailjs/browser'
import { emailConfig } from './utils/config';

//引入ant
// import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false
Vue.use(ElementUI);


function setRem() {
  const clientWidth = document.body.clientWidth
  const screenWidth = clientWidth > 900 ? 1920 : 375
  const scale = screenWidth / 16
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName('html')[0]
  // 设置根元素字体大小
  htmlDom.style.fontSize = htmlWidth / scale + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}

emailjsInit({
  publicKey: emailConfig.PUBLIC_KEY,
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
