export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}
export function validMobile(str) {
    return /^1[3-9][0-9]{9}$/.test(str)
}
export function isEmail(str) {
    return /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/.test(str)
}
export function isValidPhone(str) {
    return /^[0-9]{10}$/.test(str)
}