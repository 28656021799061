import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/termsUse',
    name: 'termsUse',
    component: () => import('@/views/TermsUse.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('@/views/Faq.vue')
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import('@/views/ContactUs.vue')
  },
  {
    path: '/Copyright',
    name: 'Copyright',
    component: () => import('@/views/Copyright.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('@/views/AboutUs.vue')
  },
  {
    path: '/Payments',
    name: 'Payments',
    component: () => import('@/views/Payments.vue')
  },
  {
    path: '/ToOnMobile',
    name: 'ToOnMobile',
    // component:ToonMobile
    component: () => import('@/views/ToonMobile.vue')
  },
  

]

const router = new VueRouter({

  routes
})

export default router
