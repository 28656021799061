import Vue from 'vue'

import SvgIcon from '@/components/svgIcon.vue'

// register globally
Vue.component('svg-icon', SvgIcon)
 

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./svg', false, /\.svg$/)
requireAll(req)

// const result = requireAll(req)

// console.log('*****result ****')
// console.log(result)
